
export const GET_ROLES_REQUEST = "GET_ROLES_REQUEST";
export const GET_ROLES_SUCCESS = "GET_ROLES_SUCCESS";
export const GET_ROLES_FAILURE = "GET_ROLES_FAILURE";

export const INVITE_MEMBER_REQUEST = "INVITE_MEMBER_REQUEST";
export const INVITE_MEMBER_SUCCESS = "INVITE_MEMBER_SUCCESS";
export const INVITE_MEMBER_FAILURE = "INVITE_MEMBER_FAILURE";

export const CREATE_ROLES_REQUEST = "CREATE_ROLES_REQUEST";
export const CREATE_ROLES_SUCCESS = "CREATE_ROLES_SUCCESS";
export const CREATE_ROLES_FAILURE = "CREATE_ROLES_FAILURE";

export const DELETE_ROLES_REQUEST = "DELETE_ROLES_REQUEST";
export const DELETE_ROLES_SUCCESS = "DELETE_ROLES_SUCCESS";
export const DELETE_ROLES_FAILURE = "DELETE_ROLES_FAILURE";

export const UPDATE_ROLES_REQUEST = "UPDATE_ROLES_REQUEST";
export const UPDATE_ROLES_SUCCESS = "UPDATE_ROLES_SUCCESS";
export const UPDATE_ROLES_FAILURE = "UPDATE_ROLES_FAILURE";

export const GET_PERMISSIONS_REQUEST = "GET_PERMISSIONS_REQUEST";
export const GET_PERMISSIONS_SUCCESS = "GET_PERMISSIONS_SUCCESS";
export const GET_PERMISSIONS_FAILURE = "GET_PERMISSIONS_FAILURE";

export const SETUP_ROLES_REQUEST = "SETUP_ROLES_REQUEST";
export const SETUP_ROLES_SUCCESS = "SETUP_ROLES_SUCCESS";
export const SETUP_ROLES_FAILURE = "SETUP_ROLES_FAILURE";

export const GET_SINGLE_ROLE_REQUEST = "GET_SINGLE_ROLE_REQUEST";
export const GET_SINGLE_ROLE_SUCCESS = "GET_SINGLE_ROLE_SUCCESS";
export const GET_SINGLE_ROLE_FAILURE = "GET_SINGLE_ROLE_FAILURE";

export const UPDATE_SETUP_ROLES_REQUEST = "UPDATE_SETUP_ROLES_REQUEST";
export const UPDATE_SETUP_ROLES_SUCCESS = "UPDATE_SETUP_ROLES_SUCCESS";
export const UPDATE_SETUP_ROLES_FAILURE = "UPDATE_SETUP_ROLES_FAILURE";

export const DELETE_SETUP_ROLES_REQUEST = "DELETE_SETUP_ROLES_REQUEST";
export const DELETE_SETUP_ROLES_SUCCESS = "DELETE_SETUP_ROLES_SUCCESS";
export const DELETE_SETUP_ROLES_FAILURE = "DELETE_SETUP_ROLES_FAILURE";

export const DELETE_COMPANY_REQUEST = "DELETE_COMPANY_REQUEST";
export const DELETE_COMPANY_SUCCESS = "DELETE_COMPANY_SUCCESS";
export const DELETE_COMPANY_FAILURE = "DELETE_COMPANY_FAILURE";

export const UPDATE_COMPANY_REQUEST = "UPDATE_COMPANY_REQUEST";
export const UPDATE_COMPANY_SUCCESS = "UPDATE_COMPANY_SUCCESS";
export const UPDATE_COMPANY_FAILURE = "UPDATE_COMPANY_FAILURE";