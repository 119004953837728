export const GET_COMBINE_REPORT_REQUEST = "GET_COGET_TASKMBINE_REPORT_REQUEST";
export const GET_COMBINE_REPORT_SUCCESS = "GET_COMBINE_REPORT_SUCCESS";
export const GET_COMBINE_REPORT_FAILURE = "GET_COMBINE_REPORT_FAILURE";

export const GET_SINGLE_REPORT_REQUEST = "GET_SINGLE_REPORT_REQUEST";
export const GET_SINGLE_REPORT_SUCCESS = "GET_SINGLE_REPORT_SUCCESS";
export const GET_SINGLE_REPORT_FAILURE = "GET_SINGLE_REPORT_FAILURE";

export const GET_TASK_REPORT_REQUEST = "GET_TASK_REPORT_REQUEST";
export const GET_TASK_REPORT_SUCCESS = "GET_TASK_REPORT_SUCCESS";
export const GET_TASK_REPORT_FAILURE = "GET_TASK_REPORT_FAILURE";

export const GET_MILESTONE_REPORT_REQUEST = "GET_MILESTONE_REPORT_REQUEST";
export const GET_MILESTONE_REPORT_SUCCESS = "GET_MILESTONE_REPORT_SUCCESS";
export const GET_MILESTONE_REPORT_FAILURE = "GET_MILESTONE_REPORT_FAILURE";

