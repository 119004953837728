
export const GET_COMPANY_USERS_REQUEST = "GET_COMPANY_USERS_REQUEST";
export const GET_COMPANY_USERS_SUCCESS = "GET_COMPANY_USERS_SUCCESS";
export const GET_COMPANY_USERS_FAILURE = "GET_COMPANY_USERS_FAILURE";

// export const ACTIVATE_COMPANY_REQUEST = "ACTIVATE_COMPANY_REQUEST";
// export const ACTIVATE_COMPANY_SUCCESS = "ACTIVATE_COMPANY_SUCCESS";
// export const ACTIVATE_COMPANY_FAILURE = "ACTIVATE_COMPANY_FAILURE";

export const CREATE_COMPANY_REQUEST = "CREATE_COMPANY_REQUEST";
export const CREATE_COMPANY_SUCCESS = "CREATE_COMPANY_SUCCESS";
export const CREATE_COMPANY_FAILURE = "CREATE_COMPANY_FAILURE";

export const DELETE_COMPANY_REQUEST = "DELETE_COMPANY_REQUEST";
export const DELETE_COMPANY_SUCCESS = "DELETE_COMPANY_SUCCESS";
export const DELETE_COMPANY_FAILURE = "DELETE_COMPANY_FAILURE";

export const UPDATE_COMPANY_REQUEST = "UPDATE_COMPANY_REQUEST";
export const UPDATE_COMPANY_SUCCESS = "UPDATE_COMPANY_SUCCESS";
export const UPDATE_COMPANY_FAILURE = "UPDATE_COMPANY_FAILURE";