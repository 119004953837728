export const GET_PROJECTS_REQUEST = "GET_PROJECTS_REQUEST";
export const GET_PROJECTS_SUCCESS = "GET_PROJECTS_SUCCESS";
export const GET_PROJECTS_FAILURE = "GET_PROJECTS_FAILURE";

export const GET_SINGLE_PROJECTS_REQUEST = "GET_SINGLE_PROJECTS_REQUEST";
export const GET_SINGLE_PROJECTS_SUCCESS = "GET_SINGLE_PROJECTS_SUCCESS";
export const GET_SINGLE_PROJECTS_FAILURE = "GET_SINGLE_PROJECTS_FAILURE";

export const CREATE_PROJECTS_REQUEST = "CREATE_PROJECTS_REQUEST";
export const CREATE_PROJECTS_SUCCESS = "CREATE_PROJECTS_SUCCESS";
export const CREATE_PROJECTS_FAILURE = "CREATE_PROJECTS_FAILURE";


export const UPDATE_PROJECTS_REQUEST = "UPDATE_PROJECTS_REQUEST";
export const UPDATE_PROJECTS_SUCCESS = "UPDATE_PROJECTS_SUCCESS";
export const UPDATE_PROJECTS_FAILURE = "UPDATE_PROJECTS_FAILURE";

export const DELETE_PROJECTS_REQUEST = "DELETE_PROJECTS_REQUEST";
export const DELETE_PROJECTS_SUCCESS = "DELETE_PROJECTS_SUCCESS";
export const DELETE_PROJECTS_FAILURE = "DELETE_PROJECTS_FAILURE";

export const GET_ACTIVITY_LOG_REQUEST = "GET_ACTIVITY_LOG_REQUEST";
export const GET_ACTIVITY_LOG_SUCCESS = "GET_ACTIVITY_LOG_SUCCESS";
export const GET_ACTIVITY_LOG_FAILURE = "GET_ACTIVITY_LOG_FAILURE";

export const INVITE_MEMBER_REQUEST = "INVITE_MEMBER_REQUEST";
export const INVITE_MEMBER_SUCCESS = "INVITE_MEMBER_SUCCESS";
export const INVITE_MEMBER_FAILURE = "INVITE_MEMBER_FAILURE";

export const GET_MEMBER_REQUEST = "GET_MEMBER_REQUEST";
export const GET_MEMBER_SUCCESS = "GET_MEMBER_SUCCESS";
export const GET_MEMBER_FAILURE = "GET_MEMBER_FAILURE";

export const UPDATE_MEMBER_REQUEST = "UPDATE_MEMBER_REQUEST";
export const UPDATE_MEMBER_SUCCESS = "UPDATE_MEMBER_SUCCESS";
export const UPDATE_MEMBER_FAILURE = "UPDATE_MEMBER_FAILURE";

export const DELETE_MEMBER_REQUEST = "DELETE_MEMBER_REQUEST";
export const DELETE_MEMBER_SUCCESS = "DELETE_MEMBER_SUCCESS";
export const DELETE_MEMBER_FAILURE = "DELETE_MEMBER_FAILURE";

